import React from "react"

const FixedRatioImage = ({ className, alt, height="", ...props }) => (
  <div className="relative" style={{ paddingTop: "56.25%", height: `${height}`}}>
    <img
      className={`absolute top-0 left-0 w-full h-auto object-cover ${className}`}
      alt={alt}
      {...props}
    />
  </div>
)

export default FixedRatioImage
