import React from "react"
import Truncate from "react-truncate"

const TruncateWrapper = ({ children, lines, className, ...props }) => <div {...props}>
  <Truncate className={className} lines={lines}>
    {children}
  </Truncate>
</div>;

export default TruncateWrapper;
