import clsx from "clsx"
import React from "react"

function Input({ className, disabled, settings = "bg-gray-100", ...props }) {
  return (
    <input
      className={clsx(
        `px-2 w-full rounded h-11 ${settings} focus:bg-white focus:border font-bold`,
        className,
        disabled && "cursor-not-allowed"
      )}
      disabled={disabled}
      {...props}
    />
  )
}

export function Textarea({
  name,
  value,
  onChange,
  rows = "3",
  cols = "50",
  placeholder,
}) {
  return (
    <textarea
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      rows={rows}
      cols={cols}
      className="px-2 pt-1 block w-full bg-gray-100 rounded focus:bg-white focus:border text-gray-500 font-semibold"
      style={{ resize: "none" }}
    />
  )
}

export default Input
