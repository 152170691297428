import React from "react"
import { Button as ReakitButton } from "reakit"
import clsx from "clsx"

const Button = React.forwardRef(
  (
    {
      children,
      size = "md",
      theme = "primary",
      className,
      active,
      disabled,
      type = "button",
      ...props
    },
    ref
  ) => {
    const paddingStyles = {
      xs: "is-xs",
      sm: "is-sm",
      md: "is-md",
      lg: "is-lg",
    }[size]

    const colorStyles = {
      primary: "is-primary",
      secondary: "is-secondary",
      tertiary: "is-tertiary",
      white: "is-white",
      inverse: active ? "is-primary" : "is-inverse",
      transparent: "bg-transparent",
      mood: "is-mood-option",
      link: "link-tertiary",
      dark: "is-dark"
    }[theme]

    return (
      <ReakitButton
        ref={ref}
        type={type}
        className={clsx(
          "btn action focus-visible:ring-2 focus-visible:ring-offset-0 focus-visible:ring-blue-400",
          className,
          paddingStyles,
          colorStyles,
          disabled && "opacity-50 cursor-not-allowed"
        )}
        disabled={disabled}
        {...props}
      >
        {children}
      </ReakitButton>
    )
  }
)
export default Button
